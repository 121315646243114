<template>
    <v-card>
        <v-card-title>
            <v-icon color="primary" x-large class="mr-3">
                $vuetify.icons.cmsIcon
            </v-icon>
            <h4 :class="fontStyle">CMS顯示資訊</h4>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="輸入搜尋文字" hide-details></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="desserts"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            :class="`elevation-1 ${fontStyle}`"
            @page-count="pageCount = $event"
            :search="search"
            :loading="loading"
            loading-text="Loading... 取得資料中"
        >
            <template v-slot:item.content="{ item }">
                <v-chip v-if="item.message.length === 0" dark color="orange">無資料</v-chip>
                <!--
                <v-chip v-if="item.message.length !== 0" dark class="ma-2" color="teal" text-color="white"
                    ><v-avatar left><v-icon>mdi-checkbox-marked-circle</v-icon> </v-avatar>顯示比數{{
                        item.message.length
                    }}
                </v-chip> -->
                <div v-if="item.message.length !== 0">
                    <div v-for="(item, i) in item.message" :key="i" class="text-h7">
                        <v-chip color="green" text-color="white" :title="item.message" class="mb-1" v-if="item.message.indexOf('@G@G') == -1">
                            <v-avatar left class="green darken-4">
                                {{ i + 1 }}
                            </v-avatar>
                            {{ item.message }}
                        </v-chip>
                        <v-chip color="orange" text-color="white" title="停車場管理CMS訊息" class="mb-1" v-else>
                            <v-avatar left class="red darken-4">
                                {{ i + 1 }}
                            </v-avatar>
                            停車場管理CMS訊息
                        </v-chip>
                    </div>
                </div>
            </template>
        </v-data-table>
        <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
    </v-card>
</template>
<script>
import { apiAllCMSMsg } from '../api/aitsApi';
export default {
    name: 'CmsPage', //cms頁面
    data() {
        return {
            fontStyle: '',
            breadcrumbs: [
                {
                    text: ':::',
                    disabled: false,
                    href: '#main',
                    title: '中央內容區，為本頁主要內容。',
                },
                {
                    text: 'home',
                    disabled: false,
                    href: '#/',
                    title: '首頁',
                },
                {
                    text: 'CMS資訊',
                    disabled: true,
                    icon: '$vuetify.icons.cmsIcon',
                },
            ],
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            search: '',
            loading: true,
            headers: [
                {
                    text: '設備編號',
                    value: 'id',
                    class: 'teal lighten-4 font-weight-black',
                    align: 'center',
                    sortable: false,
                    width: '25%',
                },
                {
                    text: '設備名稱',
                    value: 'name',
                    class: 'teal lighten-4 font-weight-black',
                    align: 'center',
                    sortable: false,
                    width: '25%',
                },
                {
                    text: '內容',
                    value: 'content',
                    class: 'teal lighten-4 font-weight-black',
                    align: 'left',
                    sortable: false,
                    width: '50%',
                },
            ],
            desserts: [],
            timer: '',
        };
    },
    methods: {
        async fetchCmsMsgList() {
            try {
                this.setCmsMsgMapByStore(await apiAllCMSMsg());
            } catch (err) {
                console.error(err);
            }
        },
        setCmsMsgMapByStore(cmsMsg) {
            const _this = this;
            const msgList = cmsMsg.data;
            msgList.forEach(function(item) {
                _this.$store.commit('setCmsMsgMap', item);
            });
            this.getCmsMsg();
        },
        getCmsMsg() {
            const _this = this;
            const timer = setInterval(function() {
                let combineMsgList = [];
                const cmsObjMap = _this.$store.getters.getCmsObjMap;
                const cmsMsgMap = _this.$store.getters.getCmsMsgMap;
                if (cmsObjMap.size) {
                    for (let [key, value] of cmsObjMap.entries()) {
                        const cmsObj = value;
                        let messages = cmsMsgMap.get(key) ? cmsMsgMap.get(key).localCmsMsgInfos : [];
                        messages = messages.filter(function(item) {
                            if (item.message.indexOf('@G@G') == -1) {
                                return item;
                            }
                        });
                        cmsObj.message = messages;
                        cmsObj.updTime = new Date();
                        combineMsgList.push(cmsObj);
                    }
                    console.log('combineMsgList', combineMsgList);
                    //無訊息的過濾
                    combineMsgList = combineMsgList.filter((msg) => msg.message.length > 0);
                    if (combineMsgList.length > 0) {
                        _this.desserts = combineMsgList;
                        _this.loading = false;
                        clearInterval(timer);
                    }
                }
            }, 200);
        },
    },

    mounted() {
        this.getCmsMsg();
    },
    created() {
        console.log('Cms start');
        const _this = this;
        this.fontStyle = this.$store.state.fontStyle;
        this.$store.commit('setBreadcrumbs', this.breadcrumbs);
        this.timer = setInterval(function() {
            _this.fetchCmsMsgList();
        }, 60 * 1000);
    },
    //銷燬前清除定時器
    beforeDestroy() {
        clearInterval(this.timer);
    },
    watch: {
        '$store.state.fontStyle': function(val) {
            if (val) {
                this.fontStyle = val;
            }
        },
    },
};
</script>
