<template>
    <v-card color="#1F7087" dark>
        <div class="d-flex flex-no-wrap justify-space-between">
            <div>
                <v-card-title class="text-h5"
                    ><h3>{{ title }}</h3></v-card-title
                >
                <v-card-subtitle></v-card-subtitle>
                <v-card-subtitle></v-card-subtitle>
                <v-card-subtitle
                    ><h2>本網站為新北市政府交通局版權所有，未經允許，不得以任何形式複製和採用</h2></v-card-subtitle
                >
                <v-card-subtitle
                    ><h2>最佳瀏覽解析度為1024x768以上，建議使用Chrome瀏覽以取得最佳瀏覽效果</h2></v-card-subtitle
                >
            </div>

            <v-avatar size="250" tile class="hidden-sm-and-down">
                <v-img alt="新北即時交通網" :src="require('../assets/about.png')"></v-img>
            </v-avatar>
        </div>
    </v-card>
</template>
<script>
export default {
    name: 'AboutMePage', //關於我們頁面
    data() {
        return {
            title: '關於我們',
            breadcrumbs: [
                {
                    text: ':::',
                    disabled: false,
                    href: '#main',
                    title: '中央內容區，為本頁主要內容。',
                },
                {
                    text: 'home',
                    disabled: false,
                    href: '#/',
                    title: '首頁',
                },
                {
                    text: '關於我們',
                    disabled: true,
                },
            ],
        };
    },
    created() {
        console.log('AboutMe start');
        this.$store.commit('setBreadcrumbs', this.breadcrumbs);
    },
    mounted() {},
};
</script>
