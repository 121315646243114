<template>
    <v-card>
        <v-card-title>
            <v-icon color="primary" x-large class="mr-3">
                mdi-clipboard-text
            </v-icon>
            <h4 :class="fontStyle">即時公告</h4>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="輸入搜尋文字" hide-details></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="desserts"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            :class="`elevation-1 ${fontStyle}`"
            @page-count="pageCount = $event"
            :search="search"
            :loading="loading"
            loading-text="Loading... 取得資料中"
        >
            <template v-slot:item.announceType="{ item }">
                <v-chip :color="chipColor(item.announceType)" text-color="white">
                    {{ item.announceType }}
                </v-chip>
            </template>
            <template v-slot:item.url="{ item }">
                <v-btn
                    class="ma-2 fontHidden"
                    text
                    icon
                    color="blue lighten-2"
                    :href="item.url"
                    v-if="item.url"
                    target="_blank"
                    title="內容來源(另開視窗)"
                >
                    <v-icon>mdi-earth</v-icon>
                    內容來源(另開視窗)
                </v-btn>
            </template>
        </v-data-table>
        <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
    </v-card>
</template>
<script>
import { apiAllAnnounce } from '../api/aitsApi';
export default {
    name: 'AnnouncePage', //即時公告頁面
    data() {
        return {
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            search: '',
            loading: true,
            fontStyle: '',
            headers: [
                {
                    text: '',
                    value: 'announceType',
                    class: 'teal lighten-4 font-weight-black ',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: '時間',
                    value: 'publishTime',
                    class: 'teal lighten-4 font-weight-black',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: '內容',
                    value: 'title',
                    class: 'teal lighten-4 font-weight-black',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: '連結',
                    value: 'url',
                    class: 'teal lighten-4 font-weight-black',
                    align: 'center',
                    sortable: false,
                },
            ],
            desserts: [],
            breadcrumbs: [
                {
                    text: ':::',
                    disabled: false,
                    href: '#main',
                    title: '中央內容區，為本頁主要內容。',
                },
                {
                    text: 'home',
                    disabled: false,
                    href: '#/',
                    title: '首頁',
                },
                {
                    text: '即時公告',
                    disabled: true,
                    icon: 'mdi-clipboard-text',
                },
            ],
        };
    },
    methods: {
        async fetchAtisDataList() {
            try {
                const annApiData = await apiAllAnnounce();
                this.desserts = annApiData.data.data;
                this.loading = false;
            } catch (err) {
                console.error(err);
            }
        },
        chipColor(item) {
            let color;
            switch (item) {
                case '公告':
                    color = 'grey darken-1';
                    break;
                default:
                    color = 'green darken-1';
                    break;
            }
            return color;
        },
    },
    mounted() {},
    created() {
        console.log('AnnouncePage start');
        this.fetchAtisDataList();
        this.fontStyle = this.$store.state.fontStyle;
        this.$store.commit('setBreadcrumbs', this.breadcrumbs);
    },
    watch: {
        '$store.state.fontStyle': function(val) {
            if (val) {
                this.fontStyle = val;
            }
        },
    },
};
</script>
