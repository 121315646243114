<template>
    <v-card color="#1F7087" dark>
        <div class="d-flex flex-no-wrap justify-space-between">
            <div>
                <v-card-title class="text-h5">
                    {{ title }}
                    <v-icon>mdi-arrow-bottom-left-thick</v-icon>
                </v-card-title>
                <v-card-subtitle> </v-card-subtitle>
                <v-card-subtitle>
                    <h2>本網站大略依無障礙網頁設計原則而建置，網站的主要內容區塊如下：</h2>
                </v-card-subtitle>
                <v-card-subtitle><h3>1.上方功能區。2.中央內容區，為本頁主要內容。3.下方功能區。</h3></v-card-subtitle>
                <v-card-subtitle>
                    <h2>本網站的快速鍵 (Accesskey) 設定如下：</h2>
                </v-card-subtitle>
                <v-card-subtitle>
                    <h3>
                        <span class="key_hlight">Alt + U</span>
                        ：上方功能區，包括回首頁、網站地圖、網站搜尋等。
                    </h3>
                </v-card-subtitle>
                <v-card-subtitle
                    ><h3>
                        <span class="key_hlight">
                            Alt + C
                        </span>
                        ：中央內容區，為本頁主要內容。
                    </h3>
                </v-card-subtitle>
                <v-card-subtitle
                    ><h3>
                        <span class="key_hlight">
                            Alt + B
                        </span>
                        ：下方功能區，包括網站資料開放宣告、下載App、版權宣告說明等。
                    </h3>
                </v-card-subtitle>
            </div>

            <v-img
                alt="新北即時交通網"
                :src="require('../assets/about.png')"
                max-width="350"
                class="hidden-sm-and-down"
            ></v-img>
        </div>
    </v-card>
</template>
<script>
export default {
    name: 'SiteMapPage', //網路地圖 頁面
    data() {
        return {
            title: '網路地圖',
            breadcrumbs: [
                {
                    text: ':::',
                    disabled: false,
                    href: '#main',
                    title: '中央內容區，為本頁主要內容。',
                },
                {
                    text: 'home',
                    disabled: false,
                    href: '#/',
                    title: '首頁',
                },
                {
                    text: '網路地圖',
                    disabled: true,
                },
            ],
        };
    },
    created() {
        console.log('AboutMe start');
        this.$store.commit('setBreadcrumbs', this.breadcrumbs);
    },
    mounted() {},
};
</script>
<style scoped>
.key_hlight {
    background: #128534;
    padding: 5px;
    font-size: 1.2em;
}
</style>
