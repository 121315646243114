<template>
    <v-card>
        <v-card-title>
            <v-icon color="primary" x-large class="mr-3">
                $vuetify.icons.parkingIcon
            </v-icon>
            <h4 :class="fontStyle">停車場資訊</h4>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="輸入搜尋文字" hide-details></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="desserts"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            :class="`elevation-1 ${fontStyle}`"
            @page-count="pageCount = $event"
            :search="search"
            :loading="loading"
            loading-text="Loading... 取得資料中"
        >
            <template v-slot:item.availableCar="{ item }">
                <v-chip
                    v-if="Number(item.availableCar) === -9"
                    dark
                    color="red darken-3 font-weight-bold"
                    title="已滿位"
                    >已滿位</v-chip
                >
                <v-chip v-else-if="!item.availableCar" dark color="blue-grey darken-1 font-weight-bold" title="無資料">
                    無資料</v-chip
                >
                <v-chip
                    v-else-if="item.availableCar <= 20"
                    dark
                    color="orange font-weight-bold"
                    :title="item.availableCar"
                >
                    {{ item.availableCar }}</v-chip
                >
                <v-chip
                    v-else-if="Number(item.availableCar) > 20"
                    dark
                    color="green darken-2 font-weight-bold"
                    :title="item.availableCar"
                >
                    {{ item.availableCar }}</v-chip
                >
            </template>
        </v-data-table>
        <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
    </v-card>
</template>
<script>
import { apiAllAvailabilityParking } from '../api/ntpcApi';
export default {
    name: 'ParkingPage', //ubike頁面
    data() {
        return {
            timer: '',
            fontStyle: '',
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            search: '',
            loading: true,
            headers: [
                {
                    text: '名稱',
                    value: 'name',
                    class: 'teal lighten-4 font-weight-black',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: '地址',
                    value: 'address',
                    class: 'teal lighten-4 font-weight-black',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: '費率',
                    value: 'rate',
                    class: 'teal lighten-4 font-weight-black',
                    align: 'center',
                    sortable: false,
                    width: '20%',
                },
                {
                    text: '電話',
                    value: 'tel',
                    class: 'teal lighten-4 font-weight-black',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: '可停總數(汽車)',
                    value: 'totalCar',
                    class: 'teal lighten-4 font-weight-black',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: '剩餘車數(汽車)',
                    value: 'availableCar',
                    class: 'teal lighten-4 font-weight-black',
                    align: 'center',
                    sortable: false,
                },
            ],
            desserts: [],
            breadcrumbs: [
                {
                    text: ':::',
                    disabled: false,
                    href: '#main',
                    title: '中央內容區，為本頁主要內容。',
                },
                {
                    text: 'home',
                    disabled: false,
                    href: '#/',
                    title: '首頁',
                },
                {
                    text: '停車場資訊',
                    disabled: true,
                    icon: '$vuetify.icons.parkingIcon',
                },
            ],
        };
    },
    methods: {
        async fetchParkingDataList() {
            try {
                const parkingMapMap = this.$store.getters.getParkingMap;
                const parkingApiData = await apiAllAvailabilityParking();
                let parkingList = parkingApiData.data;

                parkingList = parkingList.map(function(item) {
                    const parking = item;
                    parking.name = parkingMapMap.get(item.id).name;
                    parking.summary = parkingMapMap.get(item.id).summary;
                    parking.address = parkingMapMap.get(item.id).address;
                    parking.tel = parkingMapMap.get(item.id).tel;
                    parking.rate = parkingMapMap.get(item.id).rate;
                    parking.totalCar = parkingMapMap.get(item.id).totalCar;
                    return parking;
                });
                this.desserts = parkingList;
                this.loading = false;
            } catch (err) {
                console.error(err);
            }
        },
    },
    mounted() {
        const _this = this;
        const parkingTimer = setInterval(function() {
            const parkingMap = _this.$store.getters.getParkingMap;
            if (parkingMap.size) {
                _this.fetchParkingDataList();
                _this.timer = setInterval(function() {
                    _this.fetchParkingDataList();
                }, 60 * 1000 * 10);

                clearInterval(parkingTimer);
            }
        }, 200);
    },
    created() {
        console.log('parking Start');
        this.fontStyle = this.$store.state.fontStyle;
        this.$store.commit('setBreadcrumbs', this.breadcrumbs);
    },
    //銷燬前清除定時器
    beforeDestroy() {
        clearInterval(this.timer);
    },
    watch: {
        '$store.state.fontStyle': function(val) {
            if (val) {
                this.fontStyle = val;
            }
        },
    },
};
</script>
