<template>
    <v-card>
        <v-card-title>
            <v-icon color="primary" x-large class="mr-3">
                $vuetify.icons.ubikeIcon
            </v-icon>
            <h4 :class="fontStyle">YouBike顯示資訊</h4>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="輸入搜尋文字" hide-details></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="desserts"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            :class="`elevation-1 ${fontStyle}`"
            @page-count="pageCount = $event"
            :search="search"
            :loading="loading"
            loading-text="Loading... 取得資料中"
        >
        </v-data-table>
        <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
    </v-card>
</template>
<script>
import { apiAllBikeAvailability } from '../api/ptxApi';
export default {
    name: 'UbikePage', //ubike頁面
    data() {
        return {
            timer: '',
            fontStyle: '',
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            search: '',
            loading: true,
            headers: [
                {
                    text: '站名',
                    value: 'stationName',
                    class: 'teal lighten-4 font-weight-black',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: '地址',
                    value: 'stationAddress',
                    class: 'teal lighten-4 font-weight-black',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: '可借車輛',
                    value: 'AvailableRentBikes',
                    class: 'teal lighten-4 font-weight-black',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: '可還車輛',
                    value: 'AvailableReturnBikes',
                    class: 'teal lighten-4 font-weight-black',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: '可停總數',
                    value: 'bikesCapacity',
                    class: 'teal lighten-4 font-weight-black',
                    align: 'center',
                    sortable: false,
                },
            ],
            desserts: [],
            breadcrumbs: [
                {
                    text: ':::',
                    disabled: false,
                    href: '#main',
                    title: '中央內容區，為本頁主要內容。',
                },
                {
                    text: 'home',
                    disabled: false,
                    href: '#/',
                    title: '首頁',
                },
                {
                    text: 'YouBike資訊',
                    disabled: true,
                    icon: '$vuetify.icons.ubikeIcon',
                },
            ],
        };
    },
    methods: {
        async fetchUbikeDataList() {
            try {
                const bikeStationMap = this.$store.getters.getBikeStationMap;
                const bikeApiData = await apiAllBikeAvailability();
                let bikeList = bikeApiData.data;

                bikeList = bikeList.map(function(item) {
                    const bike = item;
                    bike.stationName = bikeStationMap.get(item.StationID) ? bikeStationMap.get(item.StationID).stationName.zh_tw : '無資訊';
                    bike.stationAddress = bikeStationMap.get(item.StationID) ? bikeStationMap.get(item.StationID).stationAddress.zh_tw : '無資訊';
                    bike.bikesCapacity = bikeStationMap.get(item.StationID) ? bikeStationMap.get(item.StationID).bikesCapacity : '無資訊';
                    return bike;
                });
                this.desserts = bikeList;
                this.loading = false;
            } catch (err) {
                console.error(err);
            }
        },
    },
    mounted() {
        const _this = this;
        const bikeTimer = setInterval(function() {
            const bikeStationMap = _this.$store.getters.getBikeStationMap;
            if (bikeStationMap.size) {
                _this.fetchUbikeDataList();
                _this.timer = setInterval(function() {
                    _this.fetchUbikeDataList();
                }, 60 * 1000 * 10);
                clearInterval(bikeTimer);
            }
        }, 200);
    },
    created() {
        console.log('Ubike Start');
        this.fontStyle = this.$store.state.fontStyle;
        this.$store.commit('setBreadcrumbs', this.breadcrumbs);
        // this.fetchUbikeDataList();
    },
    //銷燬前清除定時器
    beforeDestroy() {
        clearInterval(this.timer);
    },
    watch: {
        '$store.state.fontStyle': function(val) {
            if (val) {
                this.fontStyle = val;
            }
        },
    },
};
</script>
