<template>
    <v-card>
        <v-card-title>
            <v-icon color="primary" x-large class="mr-3">
                $vuetify.icons.busIcon
            </v-icon>
            <h4 :class="fontStyle">公車資訊</h4>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="輸入搜尋文字" hide-details></v-text-field>
        </v-card-title>
        <v-divider class="mx-4"></v-divider>
        <v-card-text
            >搜尋附近站牌公車資訊
            <v-chip-group v-model="nearbyStop" active-class="deep-purple accent-4 white--text" column>
                <v-chip value="0.1">200m</v-chip>

                <v-chip value="0.3">400m</v-chip>

                <v-chip value="0.6">600m</v-chip>

                <v-chip value="0.8">800m</v-chip>

                <v-chip value="1">1000m</v-chip>
            </v-chip-group>
        </v-card-text>
        <v-data-table
            :headers="headers"
            :items="desserts"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            :class="`elevation-1 ${fontStyle}`"
            @page-count="pageCount = $event"
            :search="search"
            :loading="loading"
            loading-text="Loading... 取得資料中"
            :expanded.sync="expanded"
            item-key="StationUID"
            :single-expand="singleExpand"
            @click:row="clicked"
        >
            <template v-slot:item.Stops="{ item }">
                <span v-for="(stop, i) in item.Stops" :key="i" class="text-h7 mr-2">
                    <v-chip color="blue darken-1" text-color="white" :title="stop.RouteName.Zh_tw">
                        {{ stop.RouteName.Zh_tw }}
                    </v-chip>
                </span>
            </template>
            <template v-slot:item.data-table-expand="">
                <v-btn icon color="green" class="fontHidden"> <v-icon>mdi-card-bulleted</v-icon>路線資訊</v-btn>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <v-row>
                        <v-col cols="12" sm="6" md="4" v-for="(time, i) in item.estimatedTimes" :key="i">
                            <v-card class="mx-auto" color="#26c6da" dark>
                                <v-card-title>
                                    <v-icon large left>
                                        mdi-arrow-decision-outline
                                    </v-icon>
                                    <span class="text-h5 font-weight-bold">{{ time.name }}</span>
                                </v-card-title>

                                <v-card-text class="text-h5 font-weight-bold">
                                    往 {{ time.terminal }}
                                    <v-list-item class="grow">
                                        <v-row align="center" justify="end" class="text-h4">
                                            <v-icon class="mr-1" x-large :color="timeColor(time)">
                                                mdi-bus-clock
                                            </v-icon>
                                            <span class="subheading mr-2">{{ time.estimateTime }}</span>
                                        </v-row>
                                    </v-list-item>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </td>
            </template>
        </v-data-table>
        <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
    </v-card>
</template>
<script>
import { apiAllBusRoute, apiAllEstimatedTime } from '../api/ptxApi';
export default {
    name: 'BusPage', //公車頁面
    data() {
        return {
            fontStyle: '',
            loading: true,
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            singleExpand: true,
            expanded: [],
            search: '',
            timer: '',
            busRouteMap: new Map(), //路線map
            timeMap: new Map(), //到達時間map
            desserts: [],
            nearbyStop: '', //附近站牌資訊
            busStationList: [],
            headers: [
                {
                    text: '站名',
                    value: 'StationName.Zh_tw',
                    class: 'teal lighten-4 font-weight-black',
                    align: 'center',
                    sortable: false,
                    width: '20%',
                },
                {
                    text: '地址',
                    value: 'StationAddress',
                    class: 'teal lighten-4 font-weight-black',
                    align: 'center',
                    sortable: false,
                    width: '25%',
                },
                {
                    text: '路線',
                    value: 'Stops',
                    class: 'teal lighten-4 font-weight-black ',
                    sortable: false,
                    width: '50%',
                },
                {
                    text: '詳細資訊',
                    value: 'data-table-expand',
                    class: 'teal lighten-4 font-weight-black ',
                    sortable: false,
                    width: '5%',
                },
            ],

            breadcrumbs: [
                {
                    text: ':::',
                    disabled: false,
                    href: '#main',
                    title: '中央內容區，為本頁主要內容。',
                },
                {
                    text: 'home',
                    disabled: false,
                    href: '#/',
                    title: '首頁',
                },
                {
                    text: '公車資訊',
                    disabled: true,
                    icon: '$vuetify.icons.busIcon',
                },
            ],
        };
    },
    methods: {
        async fetchPtxRouteList() {
            try {
                const _this = this;
                const busRouteApiData = await apiAllBusRoute();
                //路線資料
                const busRouteList = busRouteApiData.data;
                busRouteList.forEach(function(item) {
                    _this.setBusRouteMap(item);
                });
            } catch (err) {
                console.error(err);
            }
        },
        async fetchPtxTimeList() {
            try {
                const _this = this;
                //到達時間資料
                const estimatedTimeApiData = await apiAllEstimatedTime();
                const estimatedTimeList = estimatedTimeApiData.data;
                estimatedTimeList.forEach(function(item) {
                    _this.setTimeMap(item);
                });
                this.setEstimatedTime();
            } catch (err) {
                console.error(err);
            }
        },
        setBusRouteMap(item) {
            if (!this.busRouteMap.get(item.RouteID)) {
                this.busRouteMap.set(item.RouteID, []);
            }
            this.busRouteMap.get(item.RouteID).push(item);
        },
        setTimeMap(item) {
            this.timeMap.set(item.StopID, item);
        },
        setEstimatedTime() {
            const _this = this;
            const busStationList = this.desserts;
            busStationList.map(function(item) {
                item.estimatedTimes = _this.getStopsTime(item);
                return item;
            });
        },
        getStopsTime(station) {
            const _this = this;
            const StopStatus = {
                '0': '已到站',
                '1': '尚未發車',
                '2': '交管不停靠 ',
                '3': '末班車已過',
                '4': '今日未營運',
            };
            const Direction = {
                '0': '去程',
                '1': '返程',
                '2': '迴圈',
                '3': '未知',
            };
            let estimateTime;
            const stops = station.Stops;
            const estimateTimeList = stops.map(function(stop) {
                let estimateTimeObj = _this.timeMap.get(stop.StopID);
                if (!estimateTimeObj) {
                    return;
                }
                let routeObj = _this.busRouteMap.get(stop.RouteID);
                if (!routeObj) {
                    return;
                }
                routeObj = routeObj[0];
                if (estimateTimeObj.StopStatus === 0) {
                    estimateTime = estimateTimeObj.EstimateTime;
                } else if (estimateTimeObj.EstimateTime && estimateTimeObj.StopStatus === 1) {
                    estimateTime = estimateTimeObj.EstimateTime;
                } else {
                    estimateTime = StopStatus[estimateTimeObj.StopStatus];
                }
                let sort = estimateTimeObj.EstimateTime > 0 ? estimateTimeObj.EstimateTime : 999;
                if (Number.isInteger(Number(estimateTime))) {
                    sort = Math.round(estimateTime / 60) <= 2 ? 0 : estimateTime;
                    estimateTime =
                        Math.round(estimateTime / 60) <= 2 ? '即將到站' : Math.round(estimateTime / 60) + '分';
                }
                let goBack = Direction[estimateTimeObj.Direction];
                let terminal =
                    estimateTimeObj.Direction === 1 ? routeObj.DepartureStopNameZh : routeObj.DestinationStopNameZh;
                let name = routeObj.RouteName.Zh_tw;
                let url = routeObj.RouteMapImageUrl;
                return {
                    estimateTime: estimateTime, //預估時間(分)
                    goBack: goBack, //去返程
                    terminal: terminal, //終點站
                    name: name, //路線名稱
                    url: url, //路線圖檔
                    sort: sort, //顯示順序
                };
            });
            estimateTimeList.sort(function(a, b) {
                return a.sort - b.sort;
            });
            return estimateTimeList;
        },
        timeColor(item) {
            let estimateTime = item.estimateTime;
            if (estimateTime.indexOf('末班車') !== -1) {
                return 'green lighten-1';
            }
            if (estimateTime.indexOf('即將到站') !== -1) {
                return 'deep-orange lighten-1';
            }
            estimateTime = Number(estimateTime.split('分')[0]);
            if (estimateTime <= 5) {
                return 'deep-orange lighten-1';
            }
            if (estimateTime > 5 && estimateTime <= 15) {
                return 'yellow accent-1';
            }
            if (estimateTime > 15) {
                return 'green lighten-1';
            }
        },

        //計算google點位距離
        rad(d) {
            return (d * Math.PI) / 180.0;
        },
        //計算google點位距離
        getDistance(lat1, lng1, lat2, lng2) {
            const EARTH_RADIUS = 6371; //地球半徑6371km
            const radLat1 = this.rad(lat1);
            const radLat2 = this.rad(lat2);
            const a = radLat1 - radLat2;
            const b = this.rad(lng1) - this.rad(lng2);
            let s =
                2 *
                Math.asin(
                    Math.sqrt(
                        Math.pow(Math.sin(a / 2), 2) +
                            Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)
                    )
                );
            s = s * EARTH_RADIUS;
            s = Math.round(s * 10000) / 10000;
            return s;
        },
        //編輯desserts資料
        setDesserts() {
            const _this = this;
            const locLatitude = this.$store.getters.getLatitude;
            const locLongitude = this.$store.getters.getLongitude;
            let busStationList = this.busStationList;
            if (locLatitude && locLongitude) {
                busStationList = busStationList.filter(function(item) {
                    const distance = _this.getDistance(
                        locLatitude,
                        locLongitude,
                        item.StationPosition.PositionLat,
                        item.StationPosition.PositionLon
                    );
                    if (distance < Number(_this.nearbyStop)) {
                        return item;
                    }
                });
            }
            if (!locLatitude || !locLongitude || !_this.nearbyStop) {
                busStationList = this.busStationList;
            }
            this.desserts = busStationList;
        },
        clicked(value) {
            const index = this.expanded.indexOf(value);
            if (index === -1) {
                this.expanded.push(value);
            } else {
                this.expanded.splice(index, 1);
            }
        },
    },
    mounted() {
        const _this = this;
        const busStationData = setInterval(function() {
            const busStationObjMap = _this.$store.getters.getBusStationMap;
            if (busStationObjMap.size) {
                let busStationList = [];
                const busStationObjMap = _this.$store.getters.getBusStationMap;
                for (const value of busStationObjMap.values()) {
                    value.estimatedTimes = [];
                    busStationList.push(value);
                }
                _this.busStationList = busStationList;
                _this.setDesserts();
                _this.loading = false;
                clearInterval(busStationData);
            }
        }, 200);
    },
    created() {
        console.log('Bus Create');
        const _this = this;
        this.fetchPtxRouteList();
        this.fetchPtxTimeList();
        this.timer = setInterval(function() {
            _this.fetchPtxTimeList();
        }, 60 * 1000);
        this.fontStyle = this.$store.state.fontStyle;
        this.$store.commit('setBreadcrumbs', this.breadcrumbs);
    },
    //銷燬前清除定時器
    beforeDestroy() {
        clearInterval(this.timer);
    },
    watch: {
        '$store.state.fontStyle': function(val) {
            if (val) {
                this.fontStyle = val;
            }
        },
        nearbyStop: function() {
            const locLatitude = this.$store.getters.getLatitude;
            const locLongitude = this.$store.getters.getLongitude;
            if (!locLatitude || !locLongitude) {
                this.nearbyStop = '';
            }
            this.setDesserts();
        },
    },
};
</script>
