<template>
    <v-card>
        <v-card-title>
            <v-icon color="primary" x-large class="mr-3">
                $vuetify.icons.constrIcon
            </v-icon>
            <h4 :class="fontStyle">施工資訊</h4>
            <v-spacer></v-spacer>
            <v-text-field v-model="search" append-icon="mdi-magnify" label="輸入搜尋文字" hide-details></v-text-field>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="desserts"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            :class="`elevation-1 ${fontStyle}`"
            @page-count="pageCount = $event"
            :search="search"
            :loading="loading"
            loading-text="Loading... 取得資料中"
        >
        </v-data-table>
        <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
        </div>
    </v-card>
</template>
<script>
import { apiAllConstruction } from '../api/ntpcApi.js';
export default {
    name: 'ConstrPage', //施工頁面
    data() {
        return {
            fontStyle: '',
            page: 1,
            pageCount: 0,
            itemsPerPage: 10,
            search: '',
            loading: true,
            headers: [
                {
                    text: '施工名稱',
                    value: 'name',
                    class: 'teal lighten-4 font-weight-black',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: '施工地點',
                    value: 'location',
                    class: 'teal lighten-4 font-weight-black',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: '施工單位',
                    value: 'unit',
                    class: 'teal lighten-4 font-weight-black',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: '核准施工起日',
                    value: 'startDay',
                    class: 'teal lighten-4 font-weight-black',
                    align: 'center',
                    sortable: false,
                },
                {
                    text: '核准施工迄日',
                    value: 'endDay',
                    class: 'teal lighten-4 font-weight-black',
                    align: 'center',
                    sortable: false,
                },
            ],
            desserts: [],
            breadcrumbs: [
                {
                    text: ':::',
                    disabled: false,
                    href: '#main',
                    title: '中央內容區，為本頁主要內容。',
                },
                {
                    text: 'home',
                    disabled: false,
                    href: '#/',
                    title: '首頁',
                },
                {
                    text: '施工資訊',
                    disabled: true,
                    icon: '$vuetify.icons.constrIcon',
                },
            ],
        };
    },
    methods: {
        async fetchNtpcDataList() {
            try {
                const constrApiData = await apiAllConstruction();
                this.desserts = constrApiData.data;
                this.loading = false;
            } catch (err) {
                console.error(err);
            }
        },
    },
    mounted() {},
    created() {
        console.log('Constr Start');
        this.fetchNtpcDataList();
        this.fontStyle = this.$store.state.fontStyle;
        this.$store.commit('setBreadcrumbs', this.breadcrumbs);
    },
    watch: {
        '$store.state.fontStyle': function(val) {
            if (val) {
                this.fontStyle = val;
            }
        },
    },
};
</script>
