<template>
    <v-card class="mx-auto" max-width="1350">
        <v-card-title class="text-decoration-none d-flex justify-center">
            <a :href="url" target="_blank" :title="name + '(另開新視窗)'" :class="fontStyle">{{ name }} </a>
        </v-card-title>
        <a :href="url" target="_blank" :title="name + '(另開新視窗)'" class="text-decoration-none d-flex justify-center fontHidden">
            {{ name }}
            <v-img contain :src="require('../assets/atis.png')" :alt="name" :aspect-ratio="16 / 8"> </v-img>
        </a>
    </v-card>
</template>
<script>
export default {
    name: 'FrontPage', //首頁
    data: () => ({
        fontStyle: '',
        name: '查詢地圖版即時交通資訊，請點選下圖連結',
        url: 'https://atis.ntpc.gov.tw/',
        breadcrumbs: [
            {
                text: ':::',
                disabled: false,
                href: '#main',
                title: '中央內容區，為本頁主要內容。',
            },
            {
                text: 'home',
                disabled: false,
                href: '#/',
                title: '首頁',
            },
        ],
    }),
    created() {
        console.log('FrontPage Start');
        this.fontStyle = this.$store.state.fontStyle;
        this.$store.commit('setBreadcrumbs', this.breadcrumbs);
    },
    watch: {
        '$store.state.fontStyle': function(val) {
            if (val) {
                this.fontStyle = val;
            }
        },
    },
};
</script>
